.carousel {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.buttonWrapper {
  transform: translateY(-50%);
  pointer-events: none;
  .button:nth-child(1) {
    transform: translateX(-50%);
  }
  .button:nth-child(2) {
    transform: translateX(50%);
  }
}
.button {
  pointer-events: auto;
}
